@import '../../styles/variables.scss';

.header {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  .img {
    &.active {
      display: none;
    }

    @media(min-width: $w_desktop) {
      width: 187px;
      height: 88px;
    }
  }

  .left_mobile {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    @media(min-width: $w_desktop) {
      display: none;
      gap: 24px;
    }

    .mobile_links {
      height: 76px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: $c_white;
        font-size: 13px;
        font-style: italic;
        font-weight: 500;
        line-height: 134.438%;
        text-transform: uppercase;
      }
    }
  }

  .right_mobile {
    display: flex;
    align-items: center;
    gap: 6px;

    @media(min-width: $w_desktop) {
      display: none;
    }
  }

  .left {}

  .right {
    display: flex;
    gap: 22px;
    align-items: center;
    justify-content: space-between;

    a {
      color: $c_white;
      text-decoration: none;
      font-size: 20px;
      font-style: italic;
      font-weight: 500;
      line-height: 134.438%;
      text-transform: uppercase;
    }

    .divider {
      width: 1px;
      height: 35px;
      background: $c_yellow;
    }
  }
}

.bottom_border {
  height: 1px;
  width: 100%;
  margin-bottom: 30px;
  background: url('../../../public//assets//line.svg') repeat center center;

  @media(min-width: $w_desktop) {
    margin-top: 25px;
    margin-bottom: 50px;
  }
}
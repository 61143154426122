@import '../../styles/variables.scss';

.map {
  margin-top: 110px;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid var(--Stroke-Warm-Gradient, #FFEFEF);

  @media(min-width: $w_desktop) {
    margin-top: 200px;
  }

  img {
    width: 100%;
    height: 100%;
  }

}
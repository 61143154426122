$c_black: #000;
$c_white: #FFFFFF;
$c_white_1: #E8E8E8;
$c_white_2: #FCFCFC;
$c_blue: #2C12CD;
$c_blue_gradient: linear-gradient(160.44deg, #2C12CD 36.31%, #0E0064 117.98%);
$c_blue_gradient_1: linear-gradient(180deg, #2C12CD 0%, #160967 140.88%);


$c_yellow: #DCF44F;
$c_yellow_1: linear-gradient(90deg, #DCF44F 0%, #DFF84C 100%);
$c_yellow_2: linear-gradient(90deg, #DCF44F 0%, #DFF84C 100%);
$c_gray: #3F3F3F;
$c_gray_1: #5E5E5E;
$c_gray_2: #666;

$c_violet: #D3CCFF;



$w_desktop: '1040px';
$w_tablet: '600px';

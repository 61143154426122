@import '../../styles/variables.scss';

.button {
  font-family: Magistral;
  text-transform: uppercase;
  text-decoration: none;

  &.primary {
    background: $c_black;
    border-radius: 69px;
    border: 1px solid $c_yellow;
    padding: 8px;
    color: $c_white;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 134.438%;

    @media(min-width: $w_desktop) {
      font-size: 20px;
      padding: 15px;
    }
  }

  &.secondary {
    text-align: center;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 134.438%;
    border-radius: 20px;
    padding: 6px 33px;
    background: linear-gradient(90deg, #DCF44F 0%, #DFF84C 100%);

    @media(min-width: $w_desktop) {
      font-size: 26px;
      padding: 16px 55px;
    }
  }
}
@import '../../styles/variables.scss';

.sale {
  padding-top: 25px;

  @media(min-width: $w_desktop) {
    padding-top: 70px;
  }

  h2 {
    position: relative;
    color: $c_white;
    text-align: center;
    text-shadow: 0px 4px 106.6px rgba(0, 0, 0, 0.25), 0px 4px 36.3px rgba(0, 0, 0, 0.25), 0px 4px 23.4px rgba(0, 0, 0, 0.25);
    font-size: 30px;
    font-style: italic;
    font-weight: 800;
    text-transform: uppercase;
    max-width: 262px;
    margin: auto;

    @media(min-width: $w_desktop) {
      max-width: 559px;
      font-size: 64px;
    }

    img {
      width: 205px;
      height: 153px;
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: -1;

      @media(min-width: $w_desktop) {
        width: 307.5px;
        height: 240px;
        top: 60%;
        object-fit: cover;
      }
    }
  }

  .block {
    display: flex;
    flex-direction: column;

    @media(min-width: $w_desktop) {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 36px;
    }

    .left {
      border: 1px solid $c_gray_1;
      background: var(--Gradient, linear-gradient(180deg, #2C12CD 0%, #160967 140.88%));
      width: 100%;
      padding: 25px 30px;
      border-radius: 10px;

      @media(min-width: $w_desktop) {
        width: 416px;
        border-radius: 0;
      }

      .title {
        padding-bottom: 15px;
        border-bottom: 1px dashed #DCF44F;
        color: $c_white;
        font-size: 30px;
        font-style: italic;
        font-weight: 800;
        line-height: 100%;
        text-transform: uppercase;

        span {
          display: block;
          font-size: 20px;
          text-align: center;
          font-weight: 700;
        }

        @media(min-width: $w_desktop) {
          font-size: 40px;
        }
  
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 16px;

        .item {
          display: flex;
          gap: 53px;
          align-items: center;

          .round {
            border-radius: 50%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 47px;
            min-height: 47px;
            max-width: 47px;
            max-height: 47px;
            border: 2px $c_yellow solid;

            &::before {
              content: '';
              right: -47px;
              top: 50%;
              background: $c_yellow;
              position: absolute;
              width: 46px;
              height: 2px;
            }
          }

          .sale_title {
            color: $c_white;
            font-size: 16px;
            font-style: italic;
            font-weight: 500;
            text-transform: uppercase;

            @media(min-width: $w_desktop) {
              font-size: 24px;
            }
          }
        }
      }
    }

    .right {
      .send {
        margin: 0 auto;
        display: block;
        border-radius: 5px;
        margin-top: -20px;
        position: relative;
        z-index: 100;

        @media(min-width: $w_desktop) {
          margin-top: 30px;
          margin-left: auto;
          margin-right: unset;
          border-radius: 20px;

        }
      }
  
      .count_desktop {
        display: none;
  
        @media(min-width: $w_desktop) {
          display: block;
          border: 1px $c_yellow solid;
          height: max-content;
          width: 528px;
        }
  
        h4 {
          background: $c_yellow;
          color: $c_black;
          text-align: center;
          font-size: 32px;
          font-style: italic;
          font-weight: 500;
          line-height: 134.438%;
          text-transform: uppercase;
  
          span {
            font-weight: 800;
          }
        }
  
        .countdown {
          padding: 14px 2px;
          display: flex;
          gap: 8px;
  
          .number {
            position: relative;
            color: $c_yellow;
            text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            font-family: Apex Mk2;
            font-size: 100px;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
  
            &::after {
              position: absolute;
              content: '';
              right: -5px;
              top: 8px;
              height: 86%;
              width: 1px;
              background: $c_yellow;
            }
  
            .days {
              margin-right: 16px;
              margin-bottom: 10px;
              display: inline-block;
              margin-bottom: 4px;
              color: $c_white;
              vertical-align: bottom;
              font-family: Neue Cyr;
              font-style: normal;
              font-size: 12px;
              font-weight: 100;
              line-height: 134.438%;
              text-transform: uppercase;
            }
  
            &.full {
              width: auto;
              text-align: left;
            
              span {
                font: inherit;
                color: $c_white;
              }
  
              .sec {
                display: inline;
                color: $c_white;
                vertical-align: bottom;
                font-family: Neue Cyr;
                font-style: normal;
                font-size: 12px;
                font-weight: 100;
                line-height: 134.438%;
                text-transform: uppercase;
                position: absolute;
                right: -10px;
                bottom: 8px;
              }
  
              &::after {
                display: none;
              }
            }
          }
        }
      }

      .count_mobile {
        border-radius: 10px;
        background: radial-gradient(50% 50% at 50% 50%, #1A1A1A 0%, #0C0C0C 100%);
        box-shadow: -3px 4px 7.9px 0px rgba(0, 0, 0, 0.44), 0px 0px 2.6px 0px #DAFF00 inset;
        backdrop-filter: blur(2px);
        padding: 12px 16px;
        padding-bottom: 30px;

        .title{
          color: #FFF;
          text-align: center;
          text-shadow: 0px 4px 106.6px rgba(0, 0, 0, 0.25), 0px 4px 36.3px rgba(0, 0, 0, 0.25), 0px 4px 23.4px rgba(0, 0, 0, 0.25);
          font-size: 22px;
          font-style: italic;
          font-weight: 400;
          text-transform: uppercase;
          margin-bottom: 4px;
        }

        @media(min-width: $w_desktop) {
          display: none;
        }

        .countdown {
          display: flex;
          gap: 16px;
          justify-content: space-between;

          .section {
            flex: 1;
            padding: 3px 7px;
            border-radius: 6px;
            border: 1px solid #DCF44F;
            background: var(--Background, #000);

            .number {
              display: flex;
              gap: 9px;
              color: $c_white;
              text-align: center;
              font-family: Numbers;
              font-size: 40px;
              font-weight: 400;
              line-height: 134.438%;
              text-transform: uppercase;
              justify-content: center;
              
              span {
                font: inherit;
              }
            }

            .date {
              text-align: center;
              font-family: Neue Cyr;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 134.438%;
              text-transform: uppercase;
            }
          }
        }
      }
    }

  }

  img {
    margin: 0 auto;
    margin-top: -22px;
    display: block;
    width: 100%;
    max-width: 462px;

    @media(min-width: $w_desktop) {
      margin-top: -250px;
      max-width: 750px;
    }
  }
}
@import '../../styles/variables.scss';

.services {
  margin-top: 80px;

  @media(min-width: $w_desktop) {
    margin-top: 120px;
  }

  h1 {
    position: relative;
    color: $c_white_2;
    text-align: center;
    text-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
    font-family: Magistral;
    font-size: 36px;
    font-style: italic;
    font-weight: 800;
    text-transform: uppercase;

    @media(min-width: $w_desktop) {
      font-size: 64px;
    }
  }

  img {
    position: absolute;
    width: 344px;
    height: 350px;
    left: 50%;
    transform: translate(-50%, 0);
    top: -82px;
    z-index: -1;

    @media(min-width: $w_desktop) {

    }
  }

  .content {
    border-radius: 20px;
    border: 1px solid var(--Stroke-Warm-Gradient, #FFEFEF);
    background: var(--Dark-gradient, radial-gradient(203.55% 46.45% at 50% 50%, #0C0C0C 100%, #1A1818 100%));
    padding: 60px 15px;
    display: flex;
    flex-direction: column;
    gap: 40px;


    @media(min-width: $w_desktop) {
      padding: 60px 90px;
      gap: 0;
      flex-direction: row;
      justify-content: space-between;
    }

    .left, .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 42px;
    }

    .left {
      justify-content: center;
    }

    .listItem {
      display: flex;
      gap: 15px;
      align-items: center;

      svg {
        min-width: min-content;
      }

      h3 {
        color: #FCFCFC;
        leading-trim: both;
        text-edge: cap;
        font-family: Neue Cyr;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      p {
        color: #FCFCFC;
        text-shadow: 0px 4px 46.6px rgba(0, 0, 0, 0.25);
        font-family: Neue Cyr;
        font-size: 16px;
        font-style: normal;
        font-weight: 100;
        line-height: 130%; /* 20.8px */
        letter-spacing: 1.6px;
        text-transform: uppercase;
      }
    }
  }
}
/* 5by7 */
@font-face {
  font-family: 'Numbers';
  src: url('../../public/fonts/5by7.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

/* Apex Mk2 */
@font-face {
  font-family: 'Apex Mk2';
  src: url('../../public/fonts/ApexMk2-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Apex Mk2';
  src: url('../../public/fonts/ApexMk2-LightCondensed.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Apex Mk2';
  src: url('../../public/fonts/ApexMk2-BoldExtended.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

/* Magistral */
@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Medium_Italic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Light_Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Extra_Cond_Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Extra_Cond_Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Extra_Cond_Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Extra_Bold_Italic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Extra_Bold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Cond_Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Cond_Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Cond_Extra_Bold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Cond_Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Cond_Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Book_Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Bold_Italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Magistral';
  src: url('../../public/fonts/Magistral_Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

/* Helvetica Neue Cyr */
@font-face {
  font-family: 'Neue Cyr';
  src: url('../../public/fonts/helveticaneuecyr_ultralightitalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Cyr';
  src: url('../../public/fonts/helveticaneuecyr_ultralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Cyr';
  src: url('../../public/fonts/helveticaneuecyr_thin.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Cyr';
  src: url('../../public/fonts/helveticaneuecyr_roman.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Cyr';
  src: url('../../public/fonts/helveticaneuecyr_black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@import '../../styles//variables.scss';

.application {
  border-top: 1px solid $c_white;
  border-bottom: 1px solid $c_white;
  background: radial-gradient(203.55% 46.45% at 50% 50%, rgba(26, 24, 24, 0.80) 0%, rgba(12, 12, 12, 0.80) 100%);
  backdrop-filter: blur(95.4000015258789px);
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translate(-50%, 0);

  .container {
    width: calc(100% - var(--mobile-padding) * 2);
    margin: 0 auto;
    max-width: 1145px;
    position: relative;
  
    @media(min-width: $w_tablet) {
      width: calc(100% - var(--tablet-padding) * 2);
    }

    .content {
      padding: 10px 24px;

      @media(min-width: $w_desktop) {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      h2 {
        text-align: center;
        text-shadow: 0px 4px 16.5px rgba(0, 0, 0, 0.25);
        font-family: Magistral;
        font-size: 30px;
        font-style: italic;
        font-weight: 800;
        line-height: 134.438%;
        text-transform: uppercase;

        @media(min-width: $w_desktop) {
          font-size: 60px;
          text-align: left;
        }
      }

      .dek {
        color: $c_white_1;
        text-align: center;
        font-size: 13px;
        font-style: italic;
        font-weight: 500;
        line-height: 134.438%;
        text-transform: uppercase;

        @media(min-width: $w_desktop) {
          font-size: 24px;
          text-align: left;
        }

        span {
          font: inherit;
          color: $c_yellow;
        }
      }
    }

    .car_1 {
      display: none;
      z-index: -1;
      position: absolute;
      bottom: 25px;
      left: -70px;
      width: 145px;
      height: 126px;

      @media(min-width: $w_desktop) {
        display: block;
        top: 0;
        left: 75px;
        top: 20px;
        bottom: unset;
        width: auto;
        height: auto;
        right: 0;
        left: unset;
      }
    }

    .car_2 {
      display: none;
      z-index: -1;
      position: absolute;
      width: 199px;
      height: 146px;
      bottom: -60px;
      right: -50px;

      @media(min-width: $w_desktop) {
        display: block;
        top: 0;
        right: 75px;
        top: 20px;
        width: auto;
        height: auto;
      }
    }
  }
}
@import './variables.scss';

*, *:after, *:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: $c_black;
  color: $c_white;
  font-family: Magistral;
}

.main-content {
  background: url('../../public/assets/mobile-grid.svg') repeat center center;

  @media(min-width: $w_desktop) {
    max-width: 1400px;
    margin: 0 auto;
    background: url('../../public/assets/desktop-grid.svg') repeat center center;
  }

  .inner-content {
    max-width: 1145px;
    width: 100%;
    margin: 0 auto;
  }
}


.bg_container {
  position: relative;
  background: url('../../public/assets/img/bg.jpg') no-repeat top center;
}
@import "../../styles/variables.scss";

.container {
  margin-top: 120px;

  h1 {
    position: relative;
    margin-bottom: 20px;

    color: var(--White, #FCFCFC);
    text-align: center;
    text-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
    font-family: Magistral;
    font-size: 36px;
    font-style: italic;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;

    @media(min-width: $w_desktop) {
      font-size: 64px;
    }

    img {
      position: absolute;
      left: 50%;
      top: -120px;
      z-index: -1;
      transform: translate(-50%, 0);
    }
  }
}
@import '../../styles/variables.scss';

.container {
  --mobile-padding: 10px;
  --tablet-padding: 40px;

  max-width: 1440px;

  width: calc(100% - var(--mobile-padding) * 2);
  margin: 0 auto;

  @media(min-width: $w_tablet) {
    width: calc(100% - var(--tablet-padding) * 2);
  }
}
@import '../../styles/variables.scss';

.footer {
  margin-top: 80px;
  position: relative;
  background: linear-gradient(180deg, #2C12CD 0.15%, #160967 99.85%);
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);

  .content {
    margin: 0 auto;
    max-width: 1140px;
    padding: 60px var(--mobile-padding);
    display: flex;
    justify-content: space-between;
    gap: 30px;

    
    @media(min-width: $w_tablet) {
      padding: 60px var(--tablet-padding);
      gap: 20px;
    }

    .left {
      display: flex;
      flex-direction: column;

      .logo_container {
        @media(min-width: $w_desktop) {
          padding-bottom: 20px;
          width: max-content;
          border-bottom: 1px solid $c_white;
        }

        .footer_logo {
          @media(min-width: $w_desktop) {
            display: none;
          }
        }
  
        .footer_logo_desktop {
          display: none;
    
          @media(min-width: $w_desktop) {
            display: block;
          }
        }
      }


      .social {
        max-width: max-content;
        margin-top: 17px;
        width: 100%;
        display: flex;
        gap: 19px;
        justify-content: space-between;

        @media(min-width: $w_desktop) {
          gap: 30px;
          margin-top: 26px;
          order: 3;
        }

        .icon {
          width: 25px;
          height: 25px;

          @media(min-width: $w_desktop) {
            width: 34px;
            height: 35px;
          }
        }

        .call_icon {
          div {
            width: 25px;
            height: 25px;
            min-width: 25px;
            min-height: 25px;

            @media(min-width: $w_desktop) {
              width: 28.33px;
              height: 28.82px;
              min-width: 28.33px;
              min-height: 28.82px;
            }
          }


          @media(min-width: $w_desktop) {
            padding: 3px 3px 10px 3px;
            display: none;
          }
        }
      }

      .copyright {
        color: $c_white;
        font-size: 10px;
        font-style: italic;
        font-weight: 500;
        line-height: 134.438%;
        text-transform: uppercase;
        margin-top: 15px;

        @media(min-width: $w_desktop) {
          font-size: 24px;
          margin-top: 10px;
          order: 2;
        }

        span {
          color: $c_yellow;
          font: inherit;
        }
      }

      .address {
        color: $c_white;
        font-family: Neue Cyr;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 134.438%; /* 17.477px */
        text-transform: uppercase;
        margin-top: 46.3px;

        span {
          color: $c_yellow;
          font: inherit;
        }

        @media(min-width: $w_desktop) {
          display: none;
        }
      }
    }

    .right {
      padding-top: 7px;
      display: flex;
      gap: 20px;

      & > div {
        flex: 1;
      }

      @media(min-width: $w_desktop) {
        padding-top: 17px;
        flex: 1;
      }

      .links {
        padding: 6px 0 24px 0;
        @media(min-width: $w_desktop) {
          border-right: 1px solid $c_white;
        }

        .link_block {
          padding: 10px 10px 10px 0;
          text-align: right;
          border-bottom: 1px solid $c_white;

          a {
            text-decoration: none;
            color: #FFF;
            font-family: Magistral;
            font-size: 14px;
            font-style: italic;
            font-weight: 500;
            line-height: 134.438%; /* 32.265px */
            text-transform: uppercase;

            @media(min-width: $w_desktop) {
              font-size: 24px;
            }
          }
        }
      }

      .info {
        display: none;

        @media(min-width: $w_desktop) {
          display: block;
        }

        .item {
          text-decoration: none;
          display: block;
          padding: 12px 0;
          text-align: right;
          border-bottom: 1px solid $c_white;
          color: $c_white;
          font-family: Neue Cyr;
          font-size: 20px;
          font-weight: 500;
          line-height: 134.438%; /* 26.888px */
          text-transform: uppercase;
        }

        .address {
          white-space: nowrap;
          margin-top: 35px;
          color: $c_white;
          text-align: right;
          font-family: Neue Cyr;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 134.438%;
          text-transform: uppercase;

          span {
            color: $c_yellow;
            font: inherit;
          }
        }
      }
    }
  }
}
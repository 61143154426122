@import '../../styles/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 21px;
  gap: 20px;

  @media(min-width: $w_desktop) {
    margin-top: 30px;
    align-items: flex-start;
  }

  input {
    width: 100%;
    color: $c_white_1;
    border-radius: 10px;
    border: 1px solid $c_gray_2;
    padding: 12px;
    background: rgba(72, 72, 72, 0.44);
    
    font-family: Neue Cyr;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 134.438%;

    &:focus {
      outline: none;
      border: 1px solid $c_yellow;
    }


    @media(min-width: $w_desktop) {
      max-width: 351px;
    }

    &::placeholder {
      text-transform: uppercase;
      font-family: Neue Cyr;
      text-align: center;
      color: #666;
    }
  }

  .btns {
    margin: 20px auto 0 auto;
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-width: 179px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    @media(min-width: $w_desktop) {
      flex-direction: row;
      margin: 20px 0 0 0;
      max-width: 100%;
    }

    a {
      color: inherit;
      text-decoration: none;
      height: min-content;
      display: inline-block;

      .serviceTel {
        border: none;
        display: block;
        margin: 10px auto 0 auto;
        background: inherit;

        @media(min-width: $w_desktop) {
          display: inline;
          margin: 10px 0 0 0;
        }
      }
    }
  }
}


.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  .modalForm {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 21px;
    gap: 20px;
    padding: 50px 15px;
    width: calc(100vw - 24px);
    max-width: 350px;
    background: $c_black;
    border-radius: 20px;
    border: 1px solid $c_gray_2;

    .title {
      font-family: Magistral;
      font-size: 36px;
      font-style: italic;
      font-weight: 800;
      text-align: center;
    }

    .subtitle {
      font-family: Magistral;
      font-size: 22px;
      font-style: italic;
      font-weight: 500;
      text-align: center;
      margin-bottom: 12px;
      
      span {
        color: $c_yellow;
      }

    }

    .close {
      cursor: pointer;
      width: 40px;
      height: 40px;
      background: $c_black;
      border: 1px solid $c_gray_2;
      border-radius: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -47px;
      right: 0;


    }
  
    input {
      width: 100%;
      color: $c_white_1;
      border-radius: 10px;
      border: 1px solid $c_gray_2;
      padding: 12px;
      background: rgba(72, 72, 72, 0.44);
      
      font-family: Neue Cyr;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 134.438%;
  
      &:focus {
        outline: none;
        border: 1px solid $c_yellow;
      }
  
  
      @media(min-width: $w_desktop) {
        // max-width: 351px;
      }
  
      &::placeholder {
        text-transform: uppercase;
        font-family: Neue Cyr;
        text-align: center;
        color: #666;
      }
    }
  
    .btns {
      margin: 20px auto 0 auto;
      display: flex;
      flex-direction: column;
      gap: 6px;
      max-width: 179px;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
  
      @media(min-width: $w_desktop) {
        // flex-direction: row;
        margin: 20px 0 0 0;
        max-width: 100%;
      }

      button {
        cursor: pointer;
      }

      a {
        color: inherit;
        text-decoration: none;
        height: min-content;
        display: inline-block;
        border: none;
  
        .serviceTel {
          border: none;
          display: block;
          margin: 10px auto 0 auto;
          background: inherit;
  
          @media(min-width: $w_desktop) {
            display: inline;
            margin: 10px 0 0 0;
          }
        }
      }
    }
  }
  
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
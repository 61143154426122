
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 20px;
  border: 1px solid var(--Stroke-Warm-Gradient, #FFEFEF);
}

.mySwiper {
  height: 100px;
  box-sizing: border-box;
  margin-top: 50px;
}

.mySwiper .swiper-slide {
  width: 25%;
  aspect-ratio: 16 / 9; 
  border: 1px solid var(--Stroke-Warm-Gradient, #FFEFEF);
  border-radius: 20px;
  overflow: hidden;
}

.mySwiper .swiper-slide-thumb-active {
  border-color: #DCF44F;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.swiper-button-prev, .swiper-button-next {
  display: none;
}

@media (min-width: 1040px) {
  .mySwiper {
    height: 160px;
  }
}
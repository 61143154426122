@import '../../styles/variables.scss';

.hero {
  margin-bottom: 98px;
  position: relative;

  @media(min-width: $w_desktop) {
    margin-bottom: 148px;
  }

  h1 {
    color: $c_white_2;
    text-align: center;
    text-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
    font-size: 36px;
    font-style: italic;
    font-weight: 800;
    line-height: 134.438%;
    text-transform: uppercase;
    margin-bottom: 25px;


    @media(min-width: $w_desktop) {
      font-size: 64px;
      text-align: left;
      margin-bottom: 80px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media(min-width: $w_desktop) {
      flex-direction: row;
      justify-content: space-between;
      gap: 75px;
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 40px;

      @media(min-width: $w_desktop) {
        min-width: 688px;
      }


      .title {
        font-family: Neue Cyr;
        text-align: center;
        font-size: 24px;
        font-weight: 100;
        color: $c_white_2;

        @media(min-width: $w_desktop) {
          font-size: 48px;
          text-align: left;
        }
      }

      .info {
        max-width: 450px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        gap: 20px;
        flex-direction: column;

        p {
          color: $c_white_2;
          display: flex;
          gap: 15px;
          align-items: self-start;
          max-width: 298px;

          font-family: Neue Cyr;
          font-size: 24px;
          font-weight: 300;

          svg {
            width: 20.42px;
            height: 23.33px;
            min-width: 20.42px;
            min-height: 23.33px;
          }

        }

        @media(min-width: $w_desktop) {
          max-width: 795px;
          flex-direction: row;
          justify-content: space-between;
          gap: 40px;
          margin: unset;

        }
      }
    }

    .services {
      max-width: 450px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      @media(min-width: $w_desktop) {
        max-width: unset;
        width: unset;
      }

      &:has(.inactive:hover) {
        .active {
          background: unset;

          span {
            transform: translate(0, 0);
          }

          .arrow {
            display: none;
          }
        }
      }


      .info_item {
        position: relative;
        display: flex;
        align-items: center;
        height: 64px;
        min-width: max-content;
        text-transform: uppercase;
        font-family: Magistral;
        font-size: 16px;
        font-style: italic;
        font-weight: 800;
        line-height: 20px;
        padding-left: 70px;
        border-radius: 45px 0 0 45px;
        width: calc(100vw);
        transition: all 0.1s ease-in;

        .arrow {
          display: none;
        }

        @media(min-width: $w_desktop) {
          font-size: 20px;
        }

        &.active {
          background: $c_blue;
          cursor: pointer;

          span {
            transform: translate(12px, 0);
          }

          .arrow {
            display: block;
            color: $c_black;
            position: absolute;
            content: '→';
            font-size: 20px;
            height: 64px;
            width: 64px;
            border-radius: 50%;
            background: $c_white_2;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
          }
        }

        &:hover {
          span {
            transform: translate(12px, 0);
          }
        }

        &.inactive:hover {
          background: $c_blue;
          cursor: pointer;

          .arrow {
            display: block;
            color: $c_black;
            position: absolute;
            content: '→';
            font-size: 20px;
            height: 64px;
            width: 64px;
            border-radius: 50%;
            background: $c_white_2;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
          }
        }

        span {
          transition: all 0.2s ease-in;
        }
      }
    }
  }

}
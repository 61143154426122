@import '../../styles/variables.scss';

.container {
  margin-top: 60px;

  @media(min-width: $w_desktop) {
    margin-top: 80px;
  }

  .form_block {
    background: #1A1818;
    position: relative;
    padding: 50px 15px;
    margin: 0 auto;
    border-radius: 45px;
    border: 1px solid var(--Stroke-Warm-Gradient, #FFEFEF);
    width: 100%;
    max-width: 410px;

    input {
      max-width: 100%;
    }

    .porsche {
      display: none;

      @media(min-width: $w_desktop) {
        left: -340px;
        top: 120px;
        display: block;
        position: absolute;
      }
    }

    .audi {
      display: none;

      @media(min-width: $w_desktop) {
        right: -320px;
        bottom: 0;
        display: block;
        position: absolute;
      }
    }

    @media(min-width: $w_desktop) {
      padding: 35px;
      padding-top: 135px;
    }

    .head {
      h2 {
        color: var(--White, #FCFCFC);
        text-align: center;
        font-family: Magistral;
        font-size: 36px;
        font-style: italic;
        font-weight: 800;
        line-height: 100%; 
        text-transform: uppercase;
  
        @media(min-width: $w_desktop) {
          font-size: 60px;
        }
      }

      p {
        color: var(--Warm, #E8E8E8);
        text-align: center;
        font-family: Magistral;
        font-size: 22px;
        font-style: italic;
        font-weight: 500;
        line-height: 134.438%; /* 29.576px */
        text-transform: uppercase;

        span {
          font: inherit;
          color: $c_yellow;
        }

        @media(min-width: $w_desktop) {
          font-size: 24px;
        }
      }

      @media(min-width: $w_desktop) {
        position: absolute;
        top: 0;
        z-index: 10;
        min-width: max-content;
        left: 50%;
        transform: translate(-50%, 40px);
      }
    }
  }
}